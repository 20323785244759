<template>
  <div class="uk-padding uk-background-secondary">
    <div class="uk-flex uk-flex-center">
      <form class="uk-width-1-3@l uk-width-2-3@m">
        <div class="uk-inline uk-width">
          <span class="uk-form-icon" data-uk-search-icon />
          <input
            class="uk-input"
            name="search"
            type="text"
            placeholder="State, School, Brotherhood..."
            value=""
            required
          />
        </div>
      </form>
    </div>
    <h5 class="uk-heading-line uk-h5 uk-text-center uk-text-muted">
      <span>or choose a state</span>
    </h5>

    <div class="uk-padding-large">
      <div
        class="uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l uk-grid-divider"
        data-uk-grid="masonry: next"
      >
        <ul v-for="(group, letter) in items" :key="letter" class="uk-list">
          <li>
            <span class="uk-h1" v-html="letter" />
          </li>
          <li v-for="(item, itemIdx) in group" :key="itemIdx">
            <a class="uk-link-muted" @click="gridItemOpen(item)" v-html="item.label" />
          </li>
        </ul>
      </div>
    </div>
    <template v-if="loading">
      <div class="uk-overlay-default uk-position-cover" />
      <div class="uk-position-center" data-uk-spinner />
    </template>
  </div>
</template>

<script>
import { cloneLodash } from '@/shared/js/lodash-helpers'

export default {
  name: 'CatalogueComponent',
  props: {
    initCity: {
      type: String,
      default: null,
    },
    initGridItems: {
      type: Object,
      default: null,
    },
    initListItems: {
      type: Object,
      default: null,
    },
    initState: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      items: {},
      loading: false,
      location: {
        city: null,
        state: null,
      },
      viewType: 'grid',
    }
  },
  mounted() {
    if (this.initGridItems) {
      this.viewType = 'grid'
      this.items = this.initGridItems
    } else if (this.initListItems) {
      this.viewType = 'list'
      this.items = this.initListItems
    }
    if (this.initCity) {
      this.location.city = this.initCity
    }
    if (this.initState) {
      this.location.state = this.initState
    }
  },
  methods: {
    gridItemOpen(item) {
      let that = this
      let itemData = cloneLodash(item)

      that.loading = true
      that.items = {}
      switch (itemData.type) {
        case 'city':
          that.location.city = itemData.slug
          break
        case 'state':
          that.location.state = itemData.slug
          that.location.city = null
          break
      }
    },
  },
}
</script>
