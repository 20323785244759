<template>
  <div class="uk-margin-small-bottom">
    <input
      v-if="fieldType === 'input'"
      v-model="value"
      :class="fieldClasses"
      :name="fieldName"
      :type="inputType"
      :placeholder="fieldPlaceholder"
    />
    <textarea
      v-if="fieldType === 'textarea'"
      v-model="value"
      :class="fieldClasses"
      :name="fieldName"
      rows="5"
      :placeholder="fieldPlaceholder"
    />
    <span v-if="errorStatus" class="uk-display-block uk-text-left uk-text-small uk-text-danger" v-html="errorMessage" />
  </div>
</template>

<script>
export default {
  props: {
    errorMessage: {
      default: 'This field is required',
      type: String,
    },
    errorStatus: {
      default: false,
      type: Boolean,
    },
    inputType: {
      type: String,
      default: 'text',
    },
    fieldName: {
      type: String,
      default: '',
    },
    fieldPlaceholder: {
      default: null,
      type: String,
    },
    fieldType: {
      type: String,
      default: 'input',
    },
  },
  emits: ['update'],
  data() {
    return {
      fieldValue: null,
    }
  },
  computed: {
    fieldClasses() {
      return {
        'uk-input': this.fieldType === 'input',
        'uk-form-danger': this.errorStatus,
        'uk-textarea': this.fieldType === 'textarea',
      }
    },
    value: {
      get() {
        return this.fieldValue
      },
      set(newValue) {
        this.fieldValue = newValue
        this.$emit('update', this.fieldValue)
      },
    },
  },
  methods: {
    reset() {
      this.fieldValue = null
    },
  },
}
</script>
