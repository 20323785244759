<template>
  <autocomplete-vue
    :search="search"
    :custom-result-value="customResultValue"
    placeholder="State, School, Greek Organization..."
    custom-class="school-autocomplete"
  >
    <template #default="{ resultListProps, resultListListeners, results, getResultProps }">
      <ul v-bind="resultListProps" v-on="resultListListeners">
        <template v-for="(result, i) in results" :key="i">
          <li
            v-for="(res, index) in result[1].slice(0, 10)"
            :key="getResultProps(index, result[0]).id"
            v-bind="getResultProps(index, result[0])"
            class="uk-position-relative"
            :style="res.mascot_image ? 'background-image: none;' : ''"
          >
            <div v-if="res.mascot_image" class="uk-position-absolute school-autocomplete-mascot-image">
              <div class="uk-overflow-hidden" style="border-radius: 50%; aspect-ratio: 1 / 1">
                <img
                  class="uk-display-block uk-width-1-1 uk-height-1-1"
                  style="object-fit: cover"
                  :data-src="res.mascot_image"
                  data-uk-img
                  :alt="res.label"
                />
              </div>
            </div>
            <a :href="res.href" class="school-autocomplete-result">{{ res.label }}</a>
          </li>
        </template>
      </ul>
    </template>
  </autocomplete-vue>
</template>

<script>
import AutocompleteVue from '@/shared/components/AutocompleteVue.vue'

export default {
  name: 'SchoolAutocomplete',
  components: { AutocompleteVue },
  props: {
    searchRoute: {
      type: String,
      default: '',
    },
  },
  methods: {
    search(input) {
      return input.length > 2
        ? this.axios
            .get(`${this.searchRoute}?search=${encodeURI(input)}`)
            .then(({ data }) => Object.entries(data))
            .catch(error => console.error(error))
        : []
    },
    customResultValue() {
      return ''
    },
  },
}
</script>

<style>
.school-autocomplete .school-autocomplete-mascot-image {
  padding: 4px;
  top: 4px;
  left: 4px;
  width: 40px;
}
.school-autocomplete .autocomplete-input {
  max-width: 100%;
  width: 100%;
  background-image: none;
  color: #242628;
  padding: 12px;
  border: 1px solid #e5e7e8;
  transition: 0.2s ease-in-out;
  transition-property: color, background;
  border-radius: 60px;
  text-indent: 12px;
  height: 54px;
  font-size: 0.875rem;
  line-height: 1.5;
}
.school-autocomplete .autocomplete-input:focus,
.school-autocomplete .autocomplete-input[aria-expanded='true'] {
  outline: none;
  border-color: #afb5b8;
  background-color: #fff;
  border-radius: 60px;
  box-shadow: none;
}
.school-autocomplete .autocomplete-result-list {
  margin-top: 5px;
  border: 1px solid #e5e7e8;
  border-radius: 4px;
  transition: 0.2s ease-in-out;
  transition-property: color, background;
}
.school-autocomplete[data-loading='true']:after {
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-right-color: rgba(255, 255, 255, 0.9);
}
.school-autocomplete .autocomplete-result:not(.autocomplete-no-results) {
  padding: 0;
}
.school-autocomplete .autocomplete-result a {
  display: flex;
  padding: 12px 12px 12px 48px;
  text-align: left;
}
</style>
