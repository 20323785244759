<script setup>
import { ref } from 'vue'

defineProps({
  schoolName: {
    type: String,
    default: '',
  },
  route: {
    type: String,
    default: '',
  },
})

const name = ref('')
const email = ref('')

const isSending = ref(false)

const submitForm = async e => {
  e.preventDefault()

  const form = e.target

  const formData = new FormData(form)

  fetch(form.action, {
    method: form.method,
    body: formData,
  })
    .then(() => {
      form.reset()
    })
    .catch(error => {
      console.error('Error:', error)
    })
    .finally(() => {
      isSending.value = true
    })
}
</script>

<template>
  <a data-uk-toggle href="#help-form">Need Help with Your School Jacket Design?</a>
  <div id="help-form" data-uk-modal>
    <div class="uk-modal-dialog uk-modal-body uk-text-center uk-padding vb-rounded">
      <button class="uk-modal-close-default" type="button" uk-close></button>

      <template v-if="!isSending">
        <h2 class="uk-text-bold uk-h3">Need Help with Your School Jacket Design?</h2>
        <p class="uk-text-small">
          If you’re unsure about your school’s jacket colors or design, our team is here to help! Just fill in your
          email, and we’ll get back to you with a custom design.
        </p>

        <form method="POST" :action="route" @submit="submitForm">
          <slot></slot>
          <input type="hidden" name="form_id" value="school_no_product_special_offer" />
          <input type="hidden" name="message" :value="schoolName" />

          <div class="uk-margin-small-bottom">
            <input v-model="name" class="uk-input" name="name" type="text" required placeholder="Your Name" />
          </div>
          <div class="uk-margin-small-bottom">
            <input v-model="email" class="uk-input" name="email" type="email" required placeholder="Your Email" />
          </div>
          <div class="uk-grid-small uk-child-width-1-2@s uk-margin-top" data-uk-grid>
            <div>
              <button
                class="uk-button uk-button-default uk-modal-close uk-text-bold uk-width-1-1"
                style="border-radius: 48px"
                type="reset"
              >
                Cancel
              </button>
            </div>
            <div>
              <button
                class="uk-button uk-button-secondary uk-text-bold uk-width-1-1"
                style="border-radius: 48px"
                type="submit"
              >
                Get Help
              </button>
            </div>
          </div>
        </form>
      </template>

      <template v-else>
        <h2 class="uk-text-bold uk-h4">Thank you!</h2>
        <p class="uk-text-small">Our team will get back to you with your school jacket design shortly.</p>
      </template>
    </div>
  </div>
</template>

<style scoped></style>
