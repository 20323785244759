<script setup>
import { ref } from 'vue'

import { getImgPath } from '@/shared/js/helpers'
import ProductsSlider from '@/web/vue/shared/components/ProductsSlider.vue'

const customers = ref({
  'customer-1': getImgPath('/img/uploads/customers/customer-1.jpg'),
  'customer-2': getImgPath('/img/uploads/customers/customer-2.jpg'),
  'customer-3': getImgPath('/img/uploads/customers/customer-3.jpg'),
  'customer-4': getImgPath('/img/uploads/customers/customer-4.jpg'),
  'customer-5': getImgPath('/img/uploads/customers/customer-5.jpg'),
  'customer-6': getImgPath('/img/uploads/customers/customer-6.jpg'),
  'customer-7': getImgPath('/img/uploads/customers/customer-7.jpg'),
  'customer-8': getImgPath('/img/uploads/customers/customer-8.jpg'),
  'customer-9': getImgPath('/img/uploads/customers/customer-9.jpg'),
  'customer-10': getImgPath('/img/uploads/customers/customer-10.jpg'),
  'customer-11': getImgPath('/img/uploads/customers/customer-11.jpg'),
  'customer-12': getImgPath('/img/uploads/customers/customer-12.jpg'),
  'customer-13': getImgPath('/img/uploads/customers/customer-13.jpg'),
  'customer-14': getImgPath('/img/uploads/customers/customer-14.jpg'),
  'customer-15': getImgPath('/img/uploads/customers/customer-15.jpg'),
  'customer-16': getImgPath('/img/uploads/customers/customer-16.jpg'),
  'customer-17': getImgPath('/img/uploads/customers/customer-17.jpg'),
  'customer-18': getImgPath('/img/uploads/customers/customer-18.jpg'),
  'customer-19': getImgPath('/img/uploads/customers/customer-19.jpg'),
  'customer-20': getImgPath('/img/uploads/customers/customer-20.jpg'),
})

const logos = ref({
  1: {
    src: getImgPath('/img/uploads/logos/airbnb.png'),
    alt: 'Airbnb logo',
  },
  3: {
    src: getImgPath('/img/uploads/logos/booking.png'),
    alt: 'Booking.com logo',
  },
  5: {
    src: getImgPath('/img/uploads/logos/dominos.png'),
    alt: 'Domino’s Pizza logo',
  },
  7: {
    src: getImgPath('/img/uploads/logos/harvard.png'),
    alt: 'Harward University logo',
  },
  9: {
    src: getImgPath('/img/uploads/logos/kw.png'),
    alt: 'Keller Williams logo',
  },
  11: {
    src: getImgPath('/img/uploads/logos/teton-ridge.png'),
    alt: 'Teton Ridge logo',
  },
  13: {
    src: getImgPath('/img/uploads/logos/tiktok.png'),
    alt: 'TikTok logo',
  },
  15: {
    src: getImgPath('/img/uploads/logos/tripledot.png'),
    alt: 'Tripledot logo',
  },
  17: {
    src: getImgPath('/img/uploads/logos/youtube.png'),
    alt: 'YouTube logo',
  },
  19: {
    src: getImgPath('/img/uploads/logos/paramount.png'),
    alt: 'Paramount logo',
  },
})
</script>

<template>
  <div style="margin-top: 12px">
    <products-slider :customers="customers" :logos="logos" />
  </div>
</template>

<style scoped></style>
