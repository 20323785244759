import { createApp } from 'vue'

import { GtmPlugin } from '@/plugins/gtm'
import { useVueAxios } from '@/shared/js/axios'
import { useCj } from '@/shared/js/cj'

import ContactFormWrapper from '../shared/components/ContactFormWrapper.vue'
import LayoutMixin from '../shared/mixins/Layout'

import Catalogue from './components/CatalogueComponent.vue'
import OfferForm from './components/FormComponent.vue'
import SchoolAutocomplete from './components/SchoolAutocomplete.vue'
import NoProductsPageSlider from './components/NoProductsPageSlider.vue'
import NoProductsPageModal from './components/NoProductsPageModal.vue'
import store from './store'

const app = createApp({
  components: {
    Catalogue,
    OfferForm,
    SchoolAutocomplete,
    ContactFormWrapper,
    NoProductsPageSlider,
    NoProductsPageModal,
  },
  mixins: [LayoutMixin],
})

app.use(store)
app.use(GtmPlugin)

useVueAxios(app, store)
useCj(app, store)

app.mount('#vbApp')
