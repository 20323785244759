<template>
  <div class="uk-width uk-background-default uk-padding">
    <template v-if="loading">
      <!--LOADING OVERLAY-->
      <div class="uk-overlay-primary uk-position-cover" />
      <div class="uk-position-center vb-spinner-cart-update" data-uk-spinner />
      <!--end of LOADING OVERLAY-->
    </template>

    <div
      class="uk-text-center@m uk-padding-small uk-margin-top uk-margin-bottom uk-width-1-3@m uk-margin-auto-left uk-margin-auto-right"
    >
      <h2>Can't find the school you're looking for?</h2>
      <p>
        Fill the form below and we will get back to you with a
        <strong class="vb-color-animated">SPECIAL OFFER</strong>
        for your jacket!
      </p>
      <FormInput
        ref="formInputName"
        :error-status="errors.name"
        field-name="name"
        field-placeholder="Your Name *"
        field-type="input"
        @update="updateField('name', $event)"
      />
      <FormInput
        ref="formInputEmail"
        :error-status="errors.email"
        field-name="email"
        field-placeholder="Your Email *"
        field-type="input"
        input-type="email"
        @update="updateField('email', $event)"
      />
      <FormInput
        ref="formInputSchool"
        :error-status="errors.school"
        field-name="school"
        field-placeholder="Name of the school you're looking for *"
        field-type="input"
        @update="updateField('school', $event)"
      />
      <div class="uk-margin uk-flex uk-flex-center">
        <button
          class="uk-button uk-button-primary uk-text-bold"
          style="border-radius: 48px"
          aria-label="get a special offer"
          type="submit"
          @click="submitData()"
        >
          Get A Special Offer
        </button>
      </div>
      <div v-if="status === 'success'" class="uk-alert-success uk-width" data-uk-alert>
        <a class="uk-alert-close" data-uk-close />
        <p class="uk-text-small">Thank you for your inquiry! We will get back to you ASAP!</p>
      </div>

      <div v-if="status === 'fail'" class="uk-alert-danger" data-uk-alert>
        <a class="uk-alert-close" data-uk-close />
        <p class="uk-text-small">Something went wrong.</p>
      </div>
    </div>
  </div>
</template>

<script>
import { camelCaseLodash, cloneLodash, eachLodash } from '@/shared/js/lodash-helpers'

import FormInput from '../../shared/components/FormInput.vue'

export default {
  name: 'FormComponent',
  components: {
    FormInput,
  },
  props: {
    route: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      defaultFormData: {
        email: null,
        name: null,
        school: null,
        source: window.location.href,
      },
      formData: {},
      errors: {
        email: false,
        name: false,
        school: false,
      },
      loading: false,
      status: null,
    }
  },
  mounted() {
    this.resetData()
  },
  methods: {
    updateField(field, value) {
      this.formData[field] = value
    },
    resetData() {
      let that = this

      that.formData = cloneLodash(that.defaultFormData)
      eachLodash(that.formData, (item, field) => {
        let refId = camelCaseLodash('Form Input ' + field)

        if (typeof that.$refs[refId] !== 'undefined') {
          that.$refs[refId].reset()
        }
      })
    },
    submitData() {
      let that = this

      that.status = null
      that.errors = {
        email: false,
        name: false,
        school: false,
      }
      eachLodash(that.errors, (status, field) => {
        if (!that.formData[field]) {
          that.status = 'error'
          that.errors[field] = true
        }
      })
      let postData = new FormData()

      eachLodash(that.formData, (value, key) => {
        if (value) {
          postData.append(key, value)
        }
      })

      if (that.status === null) {
        that.loading = true
        this.axios
          .post(that.route, postData)
          .then(response => {
            that.loading = false

            if (response.data.status === 'success') {
              that.resetData()
              that.status = 'success'
              if (this.$gtm.enabled()) {
                window.dataLayer.push({
                  event: 'schoolFormSubmit',
                })
              }
            } else {
              that.status = 'fail'
            }
          })
          .catch(() => {
            that.loading = false
            that.status = 'fail'
          })
      }
    },
  },
}
</script>
