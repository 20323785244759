import { createStore } from 'vuex'

import moduleBasket from '../shared/modules/Basket'

export default createStore({
  modules: {
    basket: moduleBasket,
  },
  state: {
    gridItems: [],
    listItems: [],
  },
  mutations: {
    gridItems(state, value) {
      state.gridItems = value
    },
    listItems(state, value) {
      state.listItems = value
    },
  },
  getters: {
    gridItems: state => state.gridItems,
    listItems: state => state.listItems,
  },
})
