<template>
  <autocomplete
    :search="search"
    :placeholder="placeholder"
    :aria-label="placeholder"
    :debounce-time="debounceTime"
    :get-result-value="getResultValue"
  >
    <template
      #default="{ rootProps, inputProps, inputListeners, resultListProps, resultListListeners, results, resultProps }"
    >
      <div v-bind="rootProps" :class="['autocomplete', customClass]">
        <input
          v-bind="inputProps"
          :class="[
            'autocomplete-input',
            { 'autocomplete-input-no-results': isNoResults(results, inputProps.value, rootProps['data-loading']) },
            { 'autocomplete-input-focused': focused },
          ]"
          v-on="inputListeners"
          @focus="handleFocus"
          @blur="handleBlur"
        />
        <ul
          v-if="isNoResults(results, inputProps.value, rootProps['data-loading'])"
          class="autocomplete-result-list"
          style="position: absolute; z-index: 1; width: 100%; top: 100%"
        >
          <li class="autocomplete-result autocomplete-no-results">No results found</li>
        </ul>
        <slot
          v-if="isSlotAvailable() && !isNoResults(results, inputProps.value, rootProps['data-loading'])"
          :result-list-props="resultListProps"
          :result-list-listeners="resultListListeners"
          :results="results"
          :result-props="resultProps"
          :get-result-props="getResultProps"
        />
        <ul v-else v-bind="resultListProps" v-on="resultListListeners">
          <li v-for="(result, index) in results" :key="resultProps[index].id" v-bind="resultProps[index]">
            {{ result }}
          </li>
        </ul>
      </div>
    </template>
  </autocomplete>
</template>

<script>
import Autocomplete from '@trevoreyre/autocomplete-vue'
import '@trevoreyre/autocomplete-vue/dist/style.css'
import { useSlots } from 'vue'

export default {
  name: 'AutocompleteVue',
  components: { Autocomplete },
  props: {
    search: {
      type: Function,
      default: () => {},
    },
    placeholder: {
      type: String,
      default: '',
    },
    customClass: {
      type: String,
      default: '',
    },
    debounceTime: {
      type: Number,
      default: 500,
    },
    customResultValue: {
      type: Function,
      default: undefined,
    },
  },
  data() {
    return {
      focused: false,
    }
  },
  methods: {
    isSlotAvailable() {
      return Boolean(useSlots()?.default)
    },
    isNoResults(results, value, loading) {
      return !loading && value.length > 2 && results.length === 0 && this.focused
    },
    handleFocus() {
      this.focused = true
    },
    handleBlur() {
      this.focused = false
    },
    getResultProps(index, id = '') {
      return {
        id: `autocomplete-result-${index}${id}`,
        'data-result-index': index,
        role: 'option',
        class: 'autocomplete-result',
      }
    },
    getResultValue(result) {
      return typeof this.customResultValue === 'function' ? this.customResultValue(result) : result
    },
  },
}
</script>
